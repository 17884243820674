import { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  getActiveMarker,
  getBleLocSwitchStatus,
  getBleSensors,
  getCurrentLocation,
  getCurrentLocationChildSensors,
  getSelectedVars,
  getSensorsById,
  getSensorsByLocId,
  getSensorsByVarName,
  getTempUser,
  getUserLocation,
  getUserPosition,
} from '../state/selectors';
import { VarName } from '../utils/varNames';
import {
  setActiveMarker,
  setBleScanStatus,
  setBleSensors,
  setBleWebViewUrl,
  setClickedItem,
  setCurrentLocation,
  setSensorVars,
  setUserLocation,
  setUserPosition,
  toggleActivePlotVar,
} from '../state/actions';
import { findNearestSensor, getBleSensorTargetPosition } from '../utils/sensors';
import { persistState, StorageTypes } from '../utils/persistentState';
import { TempUser } from '../state/types';
import { isDataExpired } from '../utils/functions';
import { SensorLatest } from '../services/api';

function WebViewMessages(): null {
  const dispatch = useDispatch();
  const { user } = useAuthenticator((context) => [context.user]);
  const bleSensors = useSelector(getBleSensors);
  const sensorsById = useSelector(getSensorsById);
  const userLocation = useSelector(getUserLocation);
  const currentLocation = useSelector(getCurrentLocation);
  const locSensors = useSelector(getCurrentLocationChildSensors);
  const allowBleLocSwitch = useSelector(getBleLocSwitchStatus);
  const selectedVars = useSelector(getSelectedVars);
  const activeMarker = useSelector(getActiveMarker);
  const sensorsByVarName = useSelector(getSensorsByVarName);
  const locSensorIds = useSelector(getSensorsByLocId).get(currentLocation);
  const userPosition = useSelector(getUserPosition);
  const tempUser: TempUser | null = useSelector(getTempUser);

  const [bleActiveMarker, setBleActiveMarker] = useState<VarName>();
  const [bleDeviceId, setBleDeviceId] = useState('');

  const hasUser = user?.attributes?.email !== undefined;

  // listener for mobile app messages
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const messageHandler = (event: MessageEvent<any>) => {
      if (event.data.deviceId) setBleDeviceId(event.data.deviceId);
      else if (event.data.bleSensors) dispatch(setBleSensors(event.data.bleSensors));
      else if (event.data.bleScanStatus !== undefined)
        dispatch(setBleScanStatus(event.data.bleScanStatus));
      // to keep the portal as single page application, use url to navigate
      else if (event.data.bleWebViewUrl) dispatch(setBleWebViewUrl(event.data.bleWebViewUrl));
      else if (event.data.activeMarker) {
        const bleMarker = event.data.activeMarker;
        setBleActiveMarker(bleMarker);
        dispatch(setActiveMarker(bleMarker));
        // add to selected vars list
        if (selectedVars.indexOf(bleMarker) === -1) {
          const newVars = [...selectedVars, bleMarker];
          dispatch(toggleActivePlotVar(bleMarker));
          persistState(newVars, StorageTypes.SelectedVars);
          dispatch(setSensorVars(newVars));
        }
      }
    };

    window.addEventListener('message', messageHandler);

    return () => window.removeEventListener('message', messageHandler);
  }, [dispatch, selectedVars]);

  useEffect(() => {
    // pass all sensor names to mobile app
    if (hasUser && sensorsById.size > 0) {
      const sensorNames: { id: string; name: string }[] = [];
      sensorsById.forEach((values, keys) => {
        sensorNames.push({ id: keys, name: values.name ?? values.id });
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify({ sensorNames }));
    }
  }, [sensorsById, hasUser]);

  useEffect(() => {
    if (bleDeviceId) {
      if (posthog.get_distinct_id() !== bleDeviceId) {
        posthog.identify(bleDeviceId, { userType: 'mobile app user' });
      }
    } else if (tempUser?.credential) posthog.people.set({ userType: 'temporary' });
  }, [bleDeviceId, tempUser?.credential]);

  // pass active marker if changed in portal side for consistency
  useEffect(() => {
    const whiteList: VarName[] = [
      VarName.Co2ppm,
      VarName.TemperatureC,
      VarName.RelativeHumidity,
      VarName.ParticulateMatter,
    ];

    if (whiteList.includes(activeMarker) && bleActiveMarker !== activeMarker)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify({ activeMarker }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker]);

  // detect user location based on nearby sensors
  useEffect(() => {
    if (bleSensors) {
      for (let i = 0; i < bleSensors.length; i++) {
        const sensorLocation = sensorsById.get(bleSensors[i].id)?.location ?? '';
        if (sensorLocation) {
          if (sensorLocation !== userLocation) dispatch(setUserLocation(sensorLocation));
          if (sensorLocation !== currentLocation && allowBleLocSwitch)
            dispatch(setCurrentLocation(userLocation));
          break;
        }
      }

      if (userLocation === currentLocation) {
        // set user position if selected location is user location
        getBleSensorTargetPosition(bleSensors, locSensors, true).then((sensorPosition) => {
          if (sensorPosition) {
            dispatch(setUserPosition(sensorPosition));
          }
        });
      } else dispatch(setUserPosition(null));
    }
  }, [
    allowBleLocSwitch,
    bleSensors,
    currentLocation,
    dispatch,
    locSensors,
    sensorsById,
    userLocation,
  ]);

  // select nearest ble var sensor when allowed, and have bleSensors as well as userPosition
  useEffect(() => {
    const autoSensorSelection = allowBleLocSwitch && !!bleSensors && !!userPosition;

    if (autoSensorSelection) {
      const activeVarNameSensorIds =
        sensorsByVarName.get(activeMarker)?.filter((id) => locSensorIds?.includes(id)) ?? [];

      // detect nearest sensors
      const locVarSensors = activeVarNameSensorIds
        .map((id) => sensorsById.get(id))
        .filter(
          (sensor): sensor is SensorLatest =>
            sensor !== undefined &&
            !isDataExpired(sensor.data?.find((item) => item.varName === activeMarker)?.time ?? 0)
        );

      const nearestSensorId = bleSensors.find((s) => locSensorIds?.includes(s.id))?.id ?? '';
      const nearestSensorDetail = sensorsById.get(nearestSensorId);
      // Get nearest sensor to user with activeMarker data
      if (
        nearestSensorId &&
        activeVarNameSensorIds.includes(nearestSensorId) &&
        !isDataExpired(
          nearestSensorDetail?.data?.find((item) => item.varName === activeMarker)?.time ?? 0
        )
      ) {
        dispatch(
          setClickedItem({ id: nearestSensorId, varName: activeMarker, source: 'nearbyAuto' })
        );
      } else {
        const targetPosition = userPosition;
        const targetSensor = targetPosition
          ? findNearestSensor(locVarSensors, targetPosition)
          : undefined;
        if (targetSensor) {
          dispatch(
            setClickedItem({ id: targetSensor.id, varName: activeMarker, source: 'nearbyAuto' })
          );
        }
      }
    }
  }, [
    activeMarker,
    sensorsByVarName,
    locSensorIds,
    sensorsById,
    bleSensors,
    dispatch,
    userPosition,
    allowBleLocSwitch,
  ]);

  return null;
}

export default WebViewMessages;
